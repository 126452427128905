@import '../_var.less';

@atom: Modal;

.ModalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: @z-modal;
    transition: opacity 0.1s ease-in, z-index .01ms;

    &.ModalWrapper--hidden {
        opacity: 0;
        z-index: -1;
        transition: opacity 0.1s ease-in, z-index .15s .01ms;

        .@{atom} {
            transform: translateY(-40px);
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: rgba(0,0,0,0.3);
        z-index: -1;
    }

    .@{atom} {
        @dim-modal-w: 600px;
        @dim-modal-max-h: calc(100vh - 175px);

        transition: transform 0.15s ease-in-out;
        min-width: @dim-modal-w;
        max-width: @dim-modal-w;
        cursor: default;
        box-shadow: 0 10px 30px -10px rgba(0,0,0,0.4);
        display: flex;
        flex-flow: column nowrap;
        background: @color-white;

        .@{atom}__header {
            text-align: center;
            height: 75px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            position: relative;

            .@{atom}__closeIcon {
                position: absolute;
                top: 0;
                right: 0;
                padding: @dim-xs;
                cursor: pointer;
            }
        }

        .@{atom}__content {
            padding: 0 @dim-sm;
            margin: 0 0 @dim-sm 0;
            max-height: @dim-modal-max-h;
            overflow-y: auto;
        }

        .@{atom}__buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 0 @dim-sm @dim-sm @dim-sm;

            .Button {
                margin-bottom: 0;
            }

            .@{atom}__leftButtonsCtn {}

            .@{atom}__rightButtonsCtn {
                display: flex;
                flex-flow: row nowrap;

                .Button {
                    &:last-child,
                    &[data-action="next"],
                    &[data-action="submit"] {
                        margin-right: 0;
                    }
                }

                .CheckboxContainer .CheckboxBox {
                    margin: auto 5px auto 0;

                    .Label {
                        left: -@dim-xs;
                        font-weight: 400;
                        text-transform: none;
                        font-size: 0.7em;
                        color: grey;
                    }
                }
            }
        }
    }
}